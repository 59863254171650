import cn from 'clsx'
import s from './FittersBlock.module.scss'
import { FC, useRef, useState } from 'react'
import { Text, Button, Input, Container } from '@components/ui'
import { isMobileWidth } from '@utils/helpers'
import { useWindowSize } from '@hooks/useWindowSize'
import { FindFitterBlock } from '@generated/model/findFitterBlock'
import { Fitter as FitterType } from '@generated/model/fitter'
import Fitter from './Fitter/Fitter'
import { Search } from '@components/icons'
import Map from './Map/Map'
import { useJsApiLoader } from '@react-google-maps/api'
import { Autocomplete } from '@react-google-maps/api'
import { Product, ProductOption } from '@commerce/types/product'

type FittersBlockProps = {
  data: FindFitterBlock
  fitters: FitterType[]
  showSelectButton?: boolean
  currentFitter: FitterType | null
  setCurrentFitter: (fitter: FitterType) => void
  product?: Product
  setFitterOption?: (option: ProductOption, value: any, e: any) => void
}

const FittersBlock: FC<FittersBlockProps> = ({
  data,
  product,
  fitters,
  showSelectButton = false,
  currentFitter,
  setCurrentFitter,
  setFitterOption,
}) => {
  const [searchResult, setSearchResult] = useState<any>(null)
  const fittersContainer = useRef<HTMLDivElement>(null)
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,
    libraries: ['places'],
  })

  const [map, setMap] = useState<google.maps.Map | undefined>(undefined)
  const [zoom, setZoom] = useState<number>(12)

  const [myCurrentLocation, setMyCurrentLocation] = useState<boolean>(false)

  const handleSearch = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const address = formData.get('address') as string
    if (map) {
      var service = new window.google.maps.places.PlacesService(map)

      var request = {
        query: address,
        fields: ['name', 'geometry'],
      }

      service.findPlaceFromQuery(request, function (results, status) {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          results &&
          results.length > 0
        ) {
          const location = results[0].geometry?.location
          location && map.setCenter(location)
        }
      })
    }
  }
  function onLoad(autocomplete: any) {
    setSearchResult(autocomplete)
  }

  function onPlaceChanged() {
    if (searchResult && map) {
      const place = searchResult.getPlace()

      map.setCenter(place.geometry.location)
    }
  }

  const handleFitterClick = (fitter: FitterType) => {
    setCurrentFitter(fitter)

    setZoom(13)

    window.requestAnimationFrame(() => {
      map &&
        map.panTo({
          lat: parseFloat(fitter.latitude!),
          lng: parseFloat(fitter.longitude!),
        })
    })
  }

  const handleMapMarkerClick = (fitter: FitterType) => {
    setCurrentFitter(fitter)
    fittersContainer.current?.scrollTo({
      top: fitters.findIndex((f) => f.title === fitter.title) * 100,
      behavior: 'smooth',
    })
  }

  return (
    <>
      <Container>
        <section className={s.root}>
          <div className={s.inner}>
            <Text
              html={data.title}
              className={s.title}
              variant="sectionHeadingV2"
            />

            <div className={s.addressContainer}>
              <form onSubmit={handleSearch}>
                <div className={s.inputContainer}>
                  {isLoaded && (
                    <Autocomplete
                      onPlaceChanged={onPlaceChanged}
                      onLoad={onLoad}
                    >
                      <Input
                        type="text"
                        name="address"
                        placeholder={data.address_field_placeholder}
                        className={s.input}
                      />
                    </Autocomplete>
                  )}
                  <Button type="submit" className={s.icon} variant="naked">
                    <Search />
                  </Button>
                </div>
              </form>

              <Button
                className={s.currentLocation}
                variant="ghost"
                onClick={() => setMyCurrentLocation(true)}
              >
                {data.current_location_title}
              </Button>
            </div>
          </div>

          <div className={s.grid}>
            <div className={s.fittersCol}>
              <div className={s.fittersContainer} ref={fittersContainer}>
                {fitters.map((fitter, index) => (
                  <Fitter
                    key={`fitter-${index}`}
                    fitter={fitter}
                    onClick={() => handleFitterClick(fitter)}
                    active={currentFitter?.title === fitter.title}
                    showSelectButton={showSelectButton}
                    setFitterOption={setFitterOption}
                    product={product}
                  />
                ))}
              </div>
            </div>
            <div className={s.mapCol}>
              <div className={s.map}>
                <Map
                  fitters={fitters}
                  myCurrentLocation={myCurrentLocation}
                  setMyCurrentLocation={setMyCurrentLocation}
                  setCurrentFitter={handleMapMarkerClick}
                  currentFitter={currentFitter}
                  map={map}
                  setMap={setMap}
                  isLoaded={isLoaded}
                />
              </div>
            </div>
          </div>
        </section>
      </Container>
    </>
  )
}

export default FittersBlock
