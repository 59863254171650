import React, { useEffect } from 'react'
import {
  GoogleMap,
  Marker,
  OverlayView,
  useJsApiLoader,
} from '@react-google-maps/api'
import { Fitter as FitterType } from '@generated/model/fitter'
import s from './Map.module.scss'
import cn from 'clsx'

type LatLng = {
  lat: number
  lng: number
}

const containerStyle = {
  width: '100%',
  height: '100%',
}

const options = {
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  styles: [
    {
      featureType: 'all',
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    // {
    //   featureType: 'all',
    //   elementType: 'labels',
    //   stylers: [
    //     {
    //       visibility: 'off',
    //     },
    //   ],
    // },
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    // {
    //   featureType: 'road.arterial',
    //   elementType: 'labels.icon',
    //   stylers: [
    //     {
    //       visibility: 'off',
    //     },
    //   ],
    // },
    // {
    //   featureType: 'transit',
    //   elementType: 'all',
    //   stylers: [
    //     {
    //       visibility: 'off',
    //     },
    //   ],
    // },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          color: '#c8c8c8',
        },
        {
          visibility: 'on',
        },
      ],
    },
  ],
}

function getBoundsFromLatLngList(coords: LatLng[]): {
  south: number
  north: number
  west: number
  east: number
} {
  let south = coords[0].lat
  let north = coords[0].lat
  let west = coords[0].lng
  let east = coords[0].lng

  for (let i = 1; i < coords.length; i++) {
    south = Math.min(coords[i].lat, south)
    north = Math.max(coords[i].lat, north)
    west = Math.min(coords[i].lng, west)
    east = Math.max(coords[i].lng, east)
  }

  return { west, north, east, south }
}

function Map({
  fitters,
  myCurrentLocation,
  setMyCurrentLocation,
  setCurrentFitter,
  currentFitter,
  map,
  setMap,
  isLoaded,
  zoom = 10,
}: {
  fitters: FitterType[]
  myCurrentLocation: boolean
  setMyCurrentLocation: (val: boolean) => void
  setCurrentFitter: (val: FitterType) => void
  currentFitter: FitterType | null
  map: google.maps.Map | undefined
  setMap: (val: google.maps.Map | undefined) => void
  isLoaded: boolean
  zoom?: number
}) {
  const center1 = undefined

  const onLoad = React.useCallback(function callback(map) {
    const allCoords = fitters
      .filter((fitter) => fitter.latitude && fitter.longitude)
      .map((fitter) => {
        return {
          lat: parseFloat(fitter.latitude!),
          lng: parseFloat(fitter.longitude!),
        }
      })
    const allBounds = getBoundsFromLatLngList(allCoords)

    const bounds = new window.google.maps.LatLngBounds(allBounds)
    map.fitBounds(bounds)
    setMap(map)
    setTimeout(() => {
      map.setZoom(2.5)
    }, 200)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(undefined)
  }, [])

  useEffect(() => {
    if (myCurrentLocation && navigator.geolocation && map) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }

          requestAnimationFrame(() => {
            map.panTo(pos)
          })
          setMyCurrentLocation(false)
        },
        () => {
          console.error('Error: The Geolocation service failed.')
        }
      )
    } else {
      // Browser doesn't support Geolocation
      //   handleLocationError(false, infoWindow, map.getCenter()!)
    }
  }, [myCurrentLocation])

  const handleMarkerClick = (fitter: FitterType) => {
    setCurrentFitter(fitter)
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center1}
      options={options}
      // zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {fitters
        .filter((fitter) => fitter.latitude && fitter.longitude)
        .map((fitter, index) => {
          return (
            <OverlayView
              key={`fitter-${index}`}
              position={{
                lat: parseFloat(fitter.latitude!),
                lng: parseFloat(fitter.longitude!),
              }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <button
                className={cn(s.marker, {
                  [s.active]: currentFitter?.title === fitter.title,
                })}
                onClick={() => {
                  handleMarkerClick(fitter)
                }}
              />
            </OverlayView>
          )
        })}
    </GoogleMap>
  ) : (
    <></>
  )
}

export default React.memo(Map)
